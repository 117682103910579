import React from 'react';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';

const Documentation = () => {
  const content = (
    <div>
      <p>
        Each template style has its own set of options that control how the
        calendar is generated. For example, the Month template style includes
        options for the starting month, number of months to generate, and the
        first day of the week.
      </p>
      <p>
        Click a template style below to learn more about its associated options
        and to see example screenshots.
      </p>
      <ul>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Agenda">
            Agenda
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page">
            Day - 1 Day/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-with-Task-List">
            Day - 1 Day/Page with Task List
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-with-Timeslot-Columns">
            Day - 1 Day/Page with Timeslot Columns
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-with-Week-Grid">
            Day - 1 Day/Page with Week Grid
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-Without-Overlay">
            Day - 1 Day/Page without Overlay
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-4-Days-Page">
            Day - 4 Days/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-Calendar-Columns">
            Day - Calendar Columns
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Form-Letter">
            Form Letter
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Gantt-Chart">
            Gantt Chart
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/List">
            List
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-1-Month-Page">
            Month - 1 Month/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-2-Months-Page">
            Month - 2 Months/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-6-Months-Page">
            Month - 6 Months/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-12-Months-Page">
            Month - 12 Months/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-Multiple-Months-as-Columns">
            Month - Multiple Months as Columns
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-Multiple-Months-as-Rows">
            Month - Multiple Months as Rows
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-Span-Multiple-Pages">
            Month - Span Multiple Pages
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-Weekdays-Only">
            Month - Weekdays Only
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-2-Weeks-Page">
            Week - 2 Weeks/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Columns">
            Week - Column
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Columns-With-Color-Sidebars">
            Week - Column with Color Sidebars
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Columns-With-Task-List">
            Week - Column with Task List
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Columns-2-Weeks-Page">
            Week - Column, 2 Weeks/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Grid">
            Week - Grid
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Grid-With-Task-List">
            Week - Grid with Task List
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Multiweek">
            Week - Multiweek
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Weekdays-Only">
            Week - Weekdays Only
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year">
            Year
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-2-Per-Page">
            Year - 2 Years/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-3-Per-Page">
            Year - 3 Years/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-4-Per-Page">
            Year - 4 Years/Page
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-Months-As-Columns">
            Year - Months as Columns
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-Months-As-Rows">
            Year - Months as Rows
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-Photo-Calendar">
            Year - Photo Calendar
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options/Get-Your-Own-Custom-Template">
            Get Your Own Custom Template
          </Link>
        </li>
      </ul>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Choose-a-Template"
      commentsId="commentsplus_post_126_489"
      title="Step 2: Adjust Template Settings"
      description="Each calendar template provides options that control how the printable calendar is generated. For example, the Month template includes an option to wrap text."
      keywords="calendar templates, printable calendars, custom calendar, 4 day template, week template, month template, year template, Gantt Chart, agenda template, 2020 calendar"
      content={content}
    />
  );
};

export default Documentation;
